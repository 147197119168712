import Player from "@vimeo/player";

function init() {
	// console.log("Video scripts");

	// Banner default background
	const bannerDefaultBgVideo = document.querySelector(
		".banner-default .background-video iframe"
	);

	if (bannerDefaultBgVideo) {
		const bannerDefaultBgVideoAspectRatio = document.querySelector(
			".banner-default .background-video"
		).dataset.aspectRatio;

		bgVideoFull(
			bannerDefaultBgVideo,
			eval(bannerDefaultBgVideoAspectRatio)
		);

		bannerBgVideoFadeIn(bannerDefaultBgVideo);

		// If playing video in banner
		if (
			document.querySelector(
				".banner-default .banner-inner-wrapper.has-video-play-in-banner"
			)
		) {
			bannerBgVideoPlay(bannerDefaultBgVideo);
		}
	}

	// Banner slider background
	const bannerSliderBgVideo = document.querySelector(
		".banner-slider .slide-1 .background-video iframe"
	);

	if (bannerSliderBgVideo) {
		const bannerSliderBgVideoAspectRatio = document.querySelector(
			".banner-slider .slide-1 .background-video"
		).dataset.aspectRatio;

		bgVideoFull(bannerSliderBgVideo, eval(bannerSliderBgVideoAspectRatio));

		bannerBgVideoFadeIn(bannerSliderBgVideo);
	}

	// Banner form background
	const bannerFormBgVideo = document.querySelector(
		".banner-form .background-video iframe"
	);

	if (bannerFormBgVideo) {
		const bannerFormBgVideoAspectRatio = document.querySelector(
			".banner-form .background-video"
		).dataset.aspectRatio;

		bgVideoFull(bannerFormBgVideo, eval(bannerFormBgVideoAspectRatio));

		bannerBgVideoFadeIn(bannerFormBgVideo);
	}
}

// Background video fullwidth/height
function bgVideoFull(frame, frameRatio) {
	const viewport = frame.parentElement;

	function resizeVideo(frame) {
		const width = viewport.offsetWidth;
		const height = viewport.offsetHeight;
		const ratio = width / height;
		let targetWidth = width;
		let targetHeight = height;

		if (ratio > frameRatio) {
			// Viewport is wider than video
			// Correct the height
			targetHeight = width / frameRatio;
		} else {
			// Viewport is taller than video
			// Correct the width
			targetWidth = height * frameRatio;
		}

		// Set frame width and height
		frame.setAttribute(
			"style",
			"width: " + targetWidth + "px; height: " + targetHeight + "px"
		);
	}
	window.addEventListener("load", function () {
		resizeVideo(frame);
	});
	window.addEventListener("resize", function () {
		resizeVideo(frame);
	});
}

function bannerBgVideoFadeIn(iframe) {
	if (iframe) {
		const player = new Player(iframe);
		const wrapper = iframe.closest(".video-wrapper");
		// let videoDuration = 0;

		// On play
		player.on("play", function () {
			// console.log("Video playing");
			// function fadeOut(delay) {
			// 	setTimeout(function () {
			// 		// console.log("Fade out");
			// 		slide.classList.remove("fade-in-down-content");
			// 		slide.classList.add("fade-out-up-content");
			// 	}, delay);
			// }

			// Fade in video
			wrapper.classList.add("fade-in-video");

			// Fade out content after 2 seconds initially
			// fadeOut(2000);

			// Fade in content
			// player.getDuration().then(function (duration) {
			// 	let videoDuration = duration;

			// 	setInterval(function () {
			// 		slide.classList.remove("fade-out-up-content");
			// 		slide.classList.add("fade-in-down-content");

			// 		// Fade out content after 4 seconds
			// 		fadeOut(4000);
			// 	}, videoDuration * 1000);
			// });
		});
	}
}

function bannerBgVideoPlay(iframe) {
	const playButton = document.querySelector(
		".banner-default .background-video + .play-button"
	);

	const content = document.querySelector(".banner-default .content");

	playButton.addEventListener("click", function () {
		// Vimeo player
		const player = new Player(iframe);
		const wrapper = iframe.closest(".video-wrapper");
		let videoDuration = 0;

		// Hide play button
		this.classList.add("hidden");

		// Unmute video
		player.setMuted(false);

		// Play video from start
		player.setCurrentTime(0);

		// Remove loop
		player.setLoop(false);

		// Fade in content
		player.getDuration().then(function (duration) {
			let videoDuration = duration;

			setInterval(function () {
				if (content) {
					content.classList.add("fade-down");
				}
			}, videoDuration * 1000);
		});
	});
}

export default { init };
