import ada from "./modules/ada";
import astra from "./modules/astra";
import dialogCareers from "./modules/dialogs/careers";
import dialogContact from "./modules/dialogs/contact";
import dialogVideo from "./modules/dialogs/video";
import forms from "./modules/forms";
import lightbox from "./modules/lightbox";
import mobileMenu from "./modules/mobile-menu";
import pagination from "./modules/pagination";
import sliderBanner from "./modules/sliders/banner";
import sliderGallery from "./modules/sliders/gallery";
import sliderGrid from "./modules/sliders/grid";
import sliderLogo from "./modules/sliders/logo";
import sliderTestimonial from "./modules/sliders/testimonial";
import utils from "./modules/utils";
import video from "./modules/video";

// Utils
// Format phone numbers automatically
utils.e10kFormatPhoneNumbers();

// Remove empty paragraph tags
utils.e10kRemoveEmptyPTags(
	document.querySelectorAll(".site-footer-primary-section-4 .widget_block")
);

// Load images on hover (e.g. team headshots)
utils.e10kLoadImagesOnHover();

// Video fade
// utils.e10kVideoFadeIn(
// 	document.querySelectorAll(
// 		".background-video video, .background-video iframe"
// 	)
// );

// Banner background video fullwidth/height
// utils.e10kBannerBackgroundVideoFull();

// utils.e10kInViewport();

// ADA - Accessibility scripts, mainly fixes
// ada.init();

// Astra - Theme scripts
astra.init();

// Dialogs
dialogCareers.init();
dialogContact.init();
dialogVideo.init();

// Forms
forms.init();

// Lightbox
lightbox.init();

// Mobile menu
mobileMenu.init();

// Pagination
// pagination.init();

// Sliders
sliderBanner.init();
sliderGallery.init();
sliderGrid.init();
sliderLogo.init();
sliderTestimonial.init();

// Video
video.init();
