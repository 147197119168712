import utils from "../utils";

function init() {
	const careersDialogEl = document.querySelector(".careers-dialog");

	if (careersDialogEl) {
		const careersDialogForm = dialogEventListeners({
			open: document.querySelectorAll(".careers-form-dialog-link"),
			dialog: careersDialogEl,
			close: document.querySelector(".careers-dialog .close")
		});
	}
}

// Open dialog
function openDialog(event, dialog) {
	event.preventDefault();
	dialog.showModal();

	// Prevent body scrolling
	// ..

	// Autofocus element
	// ..
}

// Close dialog
function closeDialog(dialog) {
	dialog.close();
}

// Dialog add event listeners
function dialogEventListeners(args) {
	// Open
	args.open.forEach((el) => {
		el.addEventListener("click", (event) => {
			openDialog(event, args.dialog);
		});
	});

	// Scroll
	window.addEventListener("scroll", () => {
		document.documentElement.style.setProperty(
			"--scroll-y",
			`${window.scrollY}px`
		);
	});

	// Close
	// On ::backdrop click
	args.dialog.addEventListener("click", (event) => {
		if (event.target.nodeName === "DIALOG") {
			closeDialog(args.dialog);
		}
	});
	// On close button click
	args.close.addEventListener("click", (event) => {
		closeDialog(args.dialog);
	});
}

export default { init };
