function init() {
	// console.log("Mobile menu");

	const mobileMenuEl = document.querySelector(".mobile-navigation-wrapper");

	// Media query
	const mql = window.matchMedia("(max-width: 991px)");

	// Toggle menu
	const toggleMenuEls = document.querySelectorAll(
		".mobile-navigation-toggle-wrapper .main-menu-toggle"
	);

	if (toggleMenuEls) {
		toggleMenu(toggleMenuEls, mobileMenuEl);
	}

	// Close menu if screen size is wider than 767px
	closeMenu(mobileMenuEl, mql);

	// Toggle sub menus
	// Entire link is clickable
	// const navItemsWithChildrenEls = document.querySelectorAll(
	// 	".mobile-navigation-wrapper .menu-item-has-children > .menu-link"
	// );
	// Only icon is clickable
	const navItemsWithChildrenEls = document.querySelectorAll(
		".mobile-navigation-wrapper .menu-item-has-children > .menu-link > .sub-menu-toggle"
	);

	if (navItemsWithChildrenEls) {
		toggleSubMenus(navItemsWithChildrenEls);
	}
}

function toggleMenu(els, menu) {
	els.forEach((el) => {
		el.addEventListener("click", (e) => {
			e.preventDefault();

			// Toggle body class
			document.body.classList.toggle("mobile-menu-active");

			// Toggle active class on .mobile-navigation-wrapper
			if (menu) {
				menu.classList.toggle("active");
			}
		});
	});
}

function closeMenu(menu, mql) {
	mql.addEventListener("change", (e) => {
		if (!e.matches) {
			// Toggle body class
			document.body.classList.remove("mobile-menu-active");

			// Toggle active class on .mobile-navigation-wrapper
			if (menu) {
				menu.classList.remove("active");
			}
		}
	});
}

function toggleSubMenus(els) {
	// Remove Astra toggle
	const astraToggleEls = document.querySelectorAll(
		".ast-menu-toggle, .ast-icon, .ast-header-navigation-arrow"
	);
	astraToggleEls.forEach((el) => el.remove());

	// Toggle sub menus
	els.forEach((el) => {
		el.addEventListener("click", (e) => {
			e.preventDefault();
			el.parentElement.classList.toggle("sub-menu-active");

			// Single click opens sub menu
			// if (e.detail === 1) {
			// 	e.preventDefault();
			// 	el.classList.toggle("sub-menu-active");
			// }

			// Double click opens link
		});
	});
}

export default { init };
