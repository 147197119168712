function init() {
	const pages = document.querySelectorAll(".blog, .archive, .search");

	if (pages.length) {
		// Remove archive description from specific pages
		const pagesRemoveArchiveDescription = removeArchiveDescription();

		// Link entire post on archive pages
		const pagesLinkEntirePost = linkEntirePost();
	}

	const pageLightModeAndHeaderStyleLight = document.querySelector(
		".page-light-mode.header-style-bg-image-light-logo-text"
	);

	const pageLightModeAndHeaderStyleDark = document.querySelector(
		".page-light-mode.header-style-bg-image-dark-logo-text"
	);

	const pageLightModeAndHeaderStyleInlineImageDark = document.querySelector(
		".page-light-mode.header-style-inline-image-dark-logo-text"
	);

	const pageDarkModeAndHeaderStyleDark = document.querySelector(
		".page-dark-mode.header-style-bg-image-dark-logo-text"
	);

	// if (pageLightModeAndHeaderStyleLight) {
	// 	// Change fixed header logo to dark version
	// 	const pageChangeFixedHeaderLogoToDark = changeFixedHeaderLogoToDark();
	// }

	if (
		pageDarkModeAndHeaderStyleDark ||
		pageLightModeAndHeaderStyleInlineImageDark ||
		pageLightModeAndHeaderStyleDark
	) {
		// Change fixed header logo to light version
		changeFixedHeaderLogoToLight();
	}
}

function removeArchiveDescription() {
	const archiveDescriptionEl = document.querySelector(
		".ast-archive-description"
	);

	if (archiveDescriptionEl) {
		archiveDescriptionEl.remove();
	}
}

function linkEntirePost() {
	const posts = document.querySelectorAll(".post");

	posts.forEach((post) => {
		post.addEventListener("click", (event) => {
			location.href = post.querySelector("a").href;
		});
	});
}

function changeFixedHeaderLogoToDark() {
	const fixedHeaderLogoEl = document.querySelectorAll(
		"#ast-fixed-header .custom-logo"
	);
	const origin = window.location.origin;
	const darkLogoUrl =
		origin +
		"/wp-content/themes/elevation-ten-thousand/assets/images/content/logo-elevation-ten-thousand.svg";

	if (fixedHeaderLogoEl.length) {
		fixedHeaderLogoEl.forEach((logo) => {
			logo.setAttribute("src", darkLogoUrl);
			logo.setAttribute("data-src", darkLogoUrl);
		});
	}
}

function changeFixedHeaderLogoToLight() {
	const fixedHeaderLogoEl = document.querySelectorAll(
		"#ast-fixed-header .custom-logo"
	);
	const origin = window.location.origin;
	const lightLogoUrl =
		origin +
		"/wp-content/themes/elevation-ten-thousand/assets/images/content/logo-elevation-ten-thousand-white.svg";
	const lightMinimalLogoUrl =
		origin +
		"/wp-content/themes/elevation-ten-thousand/assets/images/content/logo-e10k-triangles-white.svg";

	if (fixedHeaderLogoEl.length) {
		fixedHeaderLogoEl.forEach((logo) => {
			if (!logo.classList.contains("minimal")) {
				logo.setAttribute("src", lightLogoUrl);
				logo.setAttribute("data-src", lightLogoUrl);
			} else {
				logo.setAttribute("src", lightMinimalLogoUrl);
				logo.setAttribute("data-src", lightMinimalLogoUrl);
			}
		});
	}
}

export default { init };
