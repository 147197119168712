import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";

function init() {
	// console.log("Lightbox");
	// console.log(lgThumbnail);

	const lightboxGalleries = document.querySelectorAll(".lightbox-gallery");

	if (lightboxGalleries.length) {
		lightboxGalleries.forEach((el) => {
			lightGallery(el, {
				download: false,
				exThumbImage: "data-thumb",
				hideScrollbar: true,
				mode: "lg-fade",
				plugins: [lgThumbnail],
				speed: 400,
				subHtmlSelectorRelative: true
			});
		});
	}
}

export default { init };
