import utils from "../utils";

function init() {
	const videoDialogEls = document.querySelectorAll(".video-dialog");

	if (videoDialogEls) {
		const videoDialogForms = dialogEventListeners({
			open: document.querySelectorAll(".video-dialog-link"),
			dialog: document.querySelectorAll(".video-dialog"),
			close: document.querySelectorAll(".video-dialog .close")
		});
	}
}

function autoplayVideo(dialog) {
	// console.log("Autoplay!");

	const iframe = dialog.querySelector("iframe");

	// Vimeo
	// Add allow="autoplay"
	const iframeSrc = iframe.src;
	iframe.setAttribute("allow", "autoplay");

	// Append &autoplay=1 to src if not already there
	// TODO: Need to check for other parameters
	if (!iframeSrc.includes("&autoplay=1")) {
		iframe.setAttribute("src", iframeSrc + "&autoplay=1");
	}
}

function stopVideo(dialog) {
	// console.log("Stop video!");

	const iframe = dialog.querySelector("iframe");

	// Vimeo
	// Remove allow="autoplay"
	iframe.setAttribute("allow", "");
	// Remove &autoplay=1
	// TODO: Need to check for other parameters
	const iframeSrc = iframe.src.replace("&autoplay=1", "");
	// Reset src
	iframe.setAttribute("src", iframeSrc);
}

function openDialog(event, dialog) {
	// console.log("Open dialog!");

	event.preventDefault();
	dialog.showModal();
	autoplayVideo(dialog);
}

// Close dialog
function closeDialog(event, dialog) {
	// console.log("Close dialog!");

	dialog.close();
	stopVideo(dialog);
}

// Dialog add event listeners
function dialogEventListeners(args) {
	// Open
	args.open.forEach((el) => {
		el.addEventListener("click", (event) => {
			openDialog(event, args.dialog[0]);
		});
	});

	// Close
	// On ::backdrop click
	args.dialog.forEach((el) => {
		el.addEventListener("click", (event) => {
			if (event.target.nodeName === "DIALOG") {
				closeDialog(event, args.dialog[0]);
			}
		});
	});

	// On close button click
	args.close.forEach((el) => {
		el.addEventListener("click", (event) => {
			closeDialog(event, args.dialog[0]);
		});
	});
}

export default { init };
