function init() {
	// console.log("Forms");

	const paymentsForm = document.querySelector(".payments-form");

	if (paymentsForm) {
		paymentsFormTotal(paymentsForm);
	}
}

function paymentsFormTotal(paymentsForm) {
	// Get all Invoice Amount fields
	function getInvoiceAmountEls() {
		const invoiceAmountEls = paymentsForm.querySelectorAll(
			'[data-label="Invoice Amount"] > input'
		);

		// Change to number instead of text
		invoiceAmountEls.forEach(function (el) {
			el.setAttribute("type", "number");

			// On change update invoice amount total
			["change", "keyup", "paste"].forEach(function (e) {
				el.addEventListener(e, function () {
					const total = getInvoiceAmountTotalValue();
					updateInvoiceAmountTotal(total);
				});
			});
		});
	}
	getInvoiceAmountEls();

	// Get invoice amount total value
	function getInvoiceAmountTotalValue() {
		const invoiceAmountEls = paymentsForm.querySelectorAll(
			'[data-label="Invoice Amount"] > input'
		);
		let total = 0;

		invoiceAmountEls.forEach(function (el) {
			const amount = el.value;

			if (amount) {
				total += parseFloat(amount);
			}
		});

		return total;
	}

	// Update invoice amount total
	function updateInvoiceAmountTotal(total) {
		const invoiceAmountTotalEl = paymentsForm.querySelector(
			".gfield.invoice-amount-total input"
		);

		invoiceAmountTotalEl.value = total;
		invoiceAmountTotalEl.setAttribute("value", total);

		// Trigger change on field
		const event = new Event("change");
		invoiceAmountTotalEl.dispatchEvent(event);
	}

	// Mutation observer for detecting when a list group is added or removed
	const listGroupEls = paymentsForm.querySelectorAll(".gfield_list_groups");
	const config = { attributes: false, childList: true, subtree: false };

	const callback = (mutationList, observer) => {
		for (const mutation of mutationList) {
			if (mutation.type === "childList") {
				const total = getInvoiceAmountTotalValue();
				updateInvoiceAmountTotal(total);
				getInvoiceAmountEls();
			}
		}
	};

	const observer = new MutationObserver(callback);
	observer.observe(listGroupEls[0], config);
}

export default { init };
